import React, { ReactNode } from "react";
import { Link, usePage } from "@inertiajs/react";

interface TransLinkProps {
    url: string;
    className?: string;
    onError?: () => void;
    method?: string;
    data?: any;
    preserveState?: boolean;
    children: ReactNode;
    disabled?: boolean;
    title?: string
    onClick?: () => void

}

const TransLink: React.FC<TransLinkProps> = ({
    url,
    className,
    onError = () => { },
    method = "get",
    preserveState = true,
    children,
    disabled = false,
    title,
    onClick = () => { },
    data
}) => {
    const { locale } = usePage().props;
    if (url && url.startsWith("/")) {
        url = url.substring(1);
    }
    return disabled ? (
        <Link

            onClick={onClick}
            title={title}
            as="button"
            disabled={disabled}
            href={`/${locale}/${url}`}
            className={className}
            onError={onError}
            method={method}
            preserveState={preserveState}
            data={data}
        >
            {children}
        </Link>
    ) : (
        <Link
            onClick={onClick}
            title={title}
            href={`/${locale}/${url}`}
            className={className}
            onError={onError}
            method={method}
            preserveState={preserveState}
            data={data}
        >
            {children}
        </Link>
    );
};

export default TransLink;
